import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const TakeAction = () => (
  <Layout>
    <SEO
      title="Take Action and Do What Feels Right for You"
      desciption="When you browse the internet you will see all sorts of advice. That would be advice from blogs including this one, YouTube videos, ebooks etc. There is so much information that it’s hard to know where to start"
    />
    <h1>Take Action and Do What Feels Right for You</h1>

    <p>As you might be able to tell from the title, this article is written in two parts. The first is to take action and the second is to do what feels right for you. While they are different pieces of advice, I decided to put them together because when you get them right, it can make all the difference and the benefits you see can be huge.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/nLKZcJj.jpg"
        alt="Take action, make a decision"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/es/@reskp"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Jametlene Reskp</a></p>
    </div>

    <p>When you browse the internet you will see all sorts of advice. That would be advice from blogs including this one, YouTube videos, ebooks etc. There is so much information that it’s hard to know where to start and exactly what to do with it. It’s easy to get stuck at the analysis stage and never make any changes because the amount of information is paralysing. Don’t worry, you are not alone and this is how social media keeps you hooked pretty much.</p>
    <p>Think about it, if everyone took the first piece of good advice they got and ran with it. Most people wouldn’t follow many influencers, you might only have one book, you wouldn’t have watched so many videos. As a result, popular videos would have a fraction of the views they have now and books would barely get any sales. Simply because everyone made good use of the information they already had and actually did something with it.</p>
    <p>The problem is that because there is so much information out there, everyone is looking for that simple solution that takes no work and gets them all the results. The other part is that some people would rather watch an entertaining video about the idea of solving their problem than actually solving their problem. Procrastination is what it was called last time I checked.</p>
    <p>This might sound harsh but hopefully you’ve decided where you want to be after you’ve read it. Good advice is everywhere and it shouldn’t take that long to find it if you’re looking properly. If you are watching for entertainment that’s fine but don’t try to convince yourself otherwise.</p>

    <h2>Take Action</h2>
    <p>This is the part that makes all the difference. All the education and knowledge in the world means nothing if you don’t apply it. That’s why you get some really smart people who everyone thinks are going to go far but end up not living up to their potential. It could be fear, procrastination, not knowing what to focus on or many other reasons.</p>
    <p>On the opposite end of the scale, it’s common to see people that aren’t the smartest in the room but go really far because they are focussed on their goal and they apply everything they learn as fast as possible.</p>
    <p>So, to make this relevant to hair care, when you find something that you think will work for you then apply it and apply it fast. Learn fast and continue, fail fast and keep trying. Hair care is not like a job or something that makes you responsible for many people. It’s something personal that will affect you or a handful of people at most. If you are trying to learn for your own children’s hair then there’s the extra responsibility but the idea is the same. It’s not world changing to have some bad hair days.</p>
    <p>The benefits of getting it right are huge of course. Time saving because you don’t have to deal with as many knots and tangles. Confidence boosting because you know your hair looks beautiful. There are many other benefits that I’m sure you’ve experienced at some point or can imagine at least.</p>
    <p>This should be motivation enough to stop reading and watching videos so you can take time to make use of everything you’ve learned and get the hair you dream of.</p>

    <h2>Do What Feel Right For You</h2>
    <p>Right after taking action comes doing what feels right for you. You can take action on what someone else says blindly which can work out for you just fine. But if it doesn’t, who will you blame? You could blame the advice giver but they didn’t force you to do it. What if it goes right, do you give them credit? maybe.</p>
    <p>Either way, taking responsibility and ownership of what you are doing is the best way to go into any new thing. That way if you succeed or fail, it’s on you and you can move forward with purpose. Analyse all the information you get, make a note of the best possible options in your eyes then follow a hair routine or a piece of advice that makes the most sense to you.</p>
    <p>For every piece of advice you get where someone says, “great, that will work” there will be another person to say, “no, that will never work”. Their reasons could be anything from them simply not liking where the advice came from, to being put off solely based on the person who gave it. All the way to them doing weeks or research and testing to disprove the method. You really can’t please everyone and you definitely shouldn’t try to because you will end up living on edge and wasting time.</p>
    <p>The outcome of your experiments should be beautiful hair that you love and you should do everything that you agree with until you get there. Make informed decisions that are logical and not emotional. Learn as much as you can without over analysing the situation and do what feels right.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default TakeAction
